<template>
  <div>
    <!-- 定义布局容器 -->
    <el-container class="home-container">

      <div style="display: flex;width: 100%;">

        <!-- 定义中间区 域-->
        <el-container>

          <!-- 定义头标签 -->
          <el-header :style=" query && query.showSticky == true ? 'display:none' : '' ">
            <div class="home-container-header">
              <div class="home-container-header-left margin-right-10">
                <el-image :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/title_icon.png' | image(200,44)" fit='contain' @click="toWeb"></el-image>
                <div class="home-container-header-source margin-left-10">
                  <div :class=" source == 'temu' ? 'home-container-header-source-item home-container-header-source-item-choose' :'home-container-header-source-item ' " @click="tochangeSource('temu')">
                    <el-image v-show="source == 'temu'" :src="source_temu | image(35,35) " alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <el-image v-show="source != 'temu'" :src="source_temu_unchoose | image(35,35) " alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <div>
                      Temu
                    </div>
                  </div>
                  <div :class=" source == 'shein' ? 'home-container-header-source-item home-container-header-source-item-choose' :'home-container-header-source-item ' " @click="tochangeSource('shein')">
                    <el-image v-show="source == 'shein'" :src="source_shein | image(35,35)" alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <el-image v-show="source != 'shein'" :src="source_shein_unchoose | image(35,35) " alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <div>
                      Shein
                    </div>
                  </div>
                  <div :class=" source == 'aliexpress' ? 'home-container-header-source-item home-container-header-source-item-choose' :'home-container-header-source-item ' " @click="tochangeSource('aliexpress')">
                    <el-image v-show="source == 'aliexpress'" :src="source_aliexpress | image(35,35)" alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <el-image v-show="source != 'aliexpress'" :src="source_aliexpress_unchoose | image(35,35) " alt="" style="width:35px;height:35px;border-radius:10px" fit='contain'></el-image>
                    <div>
                      AliExpress
                    </div>
                  </div>
                </div>
              </div>

              <div class="home-container-header-right">
                <el-popover width="270" offset="0" trigger="hover">
                  <div style="display:flex">
                    <div style="padding:10px">
                      <el-image :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/qr/sellGroup.png' | image(220,220)" fit='contain' style="width:220px;height:220px" alt=""></el-image>
                    </div>
                  </div>
                  <div style="cursor: pointer;margin-right: 20px;font-size: 14px;display:flex" slot="reference">
                    <el-image style="width: 19px;height: 19px;margin-right: 8px;" :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/qr/join_group.png' | image(19,19)" alt="" fit='contain'></el-image>
                    加入卖家群
                  </div>
                </el-popover>
                <el-popover width="550" offset="0" trigger="hover">
                  <div style="display:flex">
                    <div style="padding:20px">
                      <el-image :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/qr/qrcode.png' | image(220,220)" fit='contain' style="width:220px;height:220px" alt=""></el-image>
                      <div class="font-18" style="text-align:center;margin-top:10px">
                        扫码关注公众号
                      </div>
                    </div>
                    <div style="padding:20px">
                      <el-image :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/qr/kf_qr.png' | image(220,220)" fit='contain' style="width:220px;height:220px" alt=""></el-image>
                      <div class="font-18" style="text-align:center;margin-top:10px">
                        扫码添加客服
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer;margin-right: 20px;font-size: 14px;display:flex" slot="reference">
                    <el-image style="width: 19px;height: 19px;margin-right: 8px;" :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/lxwm.png' | image(19,19)" alt="" fit='contain'></el-image>
                    联系我们
                  </div>
                </el-popover>
                <div v-if="user && user.id && user.id > 0" class="home-container-header-right-user" style="height:35px;display:flex;align-items:center">
                  <div v-if="userBenefits && userBenefits.vipEndDate" style="margin-right:10px;color:#409EFF;font-size: 10px;">有效期至：{{ userBenefits.vipEndDate}} </div>
                  <el-dropdown>
                    <div class="home-container-header-right-user-drop">

                      <el-avatar :size='35' :src="(user.headImgUrl || '') | image(35,35)" @click.native="toCenter"></el-avatar>
                      <div style="margin-left:10px;font-size: 18px;color: #000;">{{ user.company || user.nickname || user.mobile || user.userName  || '嗨数用户'}}</div>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="toCenter">个人中心</el-dropdown-item>
                      <el-dropdown-item @click.native="logout">退出登陆</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div v-else>
                  <div class="home-container-header-right-btn" @click="toLogin">登录</div>
                </div>
              </div>
            </div>
          </el-header>
          <!-- 定义主页面结构-->
          <el-main>
            <div class="home-container-main-qr">
              <div class="home-container-main-qr-img">
                <el-image :src="'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/qr/kf_qr.png' | image(115,115)" fit='contain' style="width:115px;height:115px" alt=""></el-image>
              </div>
              <div class="home-container-main-qr-desc">
                扫一扫客服微信<br>
                <span style="color:red">领取会员</span>
              </div>
            </div>
            <div style="display: flex; margin: 0 auto;position: relative;justify-content:center">
              <div class="home-container-side" style="min-width: 135px;margin-right: 19px;background-color: #f7f7fc;" v-if="this.defaultActive!=='/home'&&this.path!=='/shopdeild'&&this.path!=='/productid'">
                <div style="position: fixed;height:680px;width: 136px; background-color: #fff;">
                  <!-- 当打开左侧菜单时 宽度为200, 当不打开时为默认值-->
                  <el-aside :width="isCollapse ?'64px':'135px'" style="margin-right: 20px;overflow: hidden;">
                    <!-- 这是左侧菜单-->
                    <!--定义折叠项-->

                    <!-- 定义布局容器 -->

                    <el-menu class="el-menu-vertical-demo" unique-opened :collapse="isCollapse" :collapse-transition="isCollapseTransition" router :default-active="defaultActive">

                      <!-- 定义一级菜单 -->
                      <el-submenu :index="menu.id+''" v-for="(menu,index) in menuList" :key="menu.id" v-if="index!==0">
                        <!-- 定义一级菜单模版 -->
                        <template slot="title">
                          <!-- 定义左侧图标-->
                          <!-- 定义菜单名称-->
                          <!-- <i class="el-icon-menu"></i> -->
                          <img style="width: 19px;height: 19px;margin-right: 10px;" :src="menu.icon" alt="">
                          <span>{{menu.name}}</span>
                          <span class="red" v-if="menu.val > 0" @click="vae(menu.index)"> {{menu.val}}</span>
                        </template>
                        <!-- 定义二级菜单 -->
                        <el-menu-item :index="childrenMenu.path" v-for="childrenMenu in menu.children" :key="childrenMenu.id" @click="defaultActiveMenu(childrenMenu.path,menu.name)">

                          <template slot="title">

                            <span>{{childrenMenu.name}}</span> <span class="red" v-if="childrenMenu.val > 0" @click="vae"> {{childrenMenu.val}}</span>

                          </template>
                        </el-menu-item>
                      </el-submenu>
                    </el-menu>
                  </el-aside>
                </div>
                <!-- productid -->
              </div>
              <!-- 定义路由展现页面-->
              <router-view :class="this.defaultActive!=='/home'&&this.defaultActive!=='/shopdeild'&&this.defaultActive!=='/shopdeild'?'':''" @sticky='dealSticky'></router-view>
            </div>
            <same_pic :showSearch="showSearch" @close="showSearch = false"></same_pic>
          </el-main>
        </el-container>
      </div>

    </el-container>
    <div style="text-align: center;height: 100px;padding: 50px;background-color: #f7f7fc;">
      <div class="titbox-bottom">嗨数云数据丨跨境选品和数据分析 · <a style="color:#000" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024084167号-1</a></div>
      <!-- <div style="width:100%;text-align: center;">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502006698" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                 <img  style="width:18px" src="../../assets/images/ba.png" alt="">
                 <p style="float:right;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#000;">浙公网安备 33010502006698号</p >
              </a>
          </div>
        -->
    </div>
    <el-dialog :visible.sync="show_vip" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未开通会员，请联系客服开通</div>
        <img src="../../assets/imgs/kf_qr.png" style="width:220px;height:220px" alt="">
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="show_vip = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showLogin" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未登录，请登录</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { loginout, getUserInfo } from '../../api/api'
import request from '../../api/request'
import { Base64 } from 'js-base64';
import same_pic from '../../components/same_pic.vue'
export default {

  data() {
    return {
      menuval: 0,
      menuvala: 0,
      menuvalb: 0,
      menuvalc: 0,
      menuvald: 0,
      editableTabsValue: 0,
      editableTabs: [],
      source_temu: require('../../assets/images/source_temu.png'),
      source_temu_unchoose: require('../../assets/images/source_temu_unchoose.png'),
      source_shein: require('../../assets/images/source_shein.jpg'),
      source_shein_unchoose: require('../../assets/images/source_shein_unchoose.png'),
      source_aliexpress: require('../../assets/images/source_aliexpress.png'),
      source_aliexpress_unchoose: require('../../assets/images/source_aliexpress_unchoose.png'),
      //左侧菜单数据
      menuList: [
        {
          id: 0,
          name: '首页',

          path: '/home',
        },

        {
          id: 1,
          name: '商品',
          valea: '',
          icon: require('../../assets/images/spicon.png'),
          children: [
            {
              valea: '',
              id: 2,
              path: '/buffer?type=1&status=1',
              name: '商品热销榜'
            },
            {
              valea: '',
              id: 3,
              path: '/buffer?type=1&status=2',
              name: '日销排行榜'
            },
            {
              valea: '',
              id: 4,
              path: '/buffer?type=1&status=3',
              name: '新品热销榜'
            },

            {
              valea: '',
              id: 5,
              path: '/buffer??type=1&status=4',
              name: '潜力爆品款'
            },
            // {
            //   valea: '',
            //   id:6,
            //   path:'/buffer??type=1&status=5',
            //   name:'活动热销榜'
            // },
          ]
        },
        {
          id: 7,
          name: '店铺',
          val: '',
          icon: require('../../assets/images/dpicon.png'),
          children: [
            {
              id: 8,
              valea: '',
              path: '/buffer?type=2&status=1',
              name: '热销店铺榜',

            },
            {
              id: 9,
              valea: '',
              path: '/buffer?type=2&status=2',
              name: '持续热店榜',
              val: 0
            },
            {
              id: 10,
              valea: '',
              path: '/buffer?type=2&status=3',
              name: '新店热销榜',
              val: 0
            },
            // {
            //   id:11,
            //   valea: '',
            //   path:'/buffer?type=2&status=4',
            //   name:'热搜店铺榜',
            //   val:0
            // },
            // {
            //   id:12,
            //   valea: '',
            //   path:'/buffer?type=2&status=5',
            //   name:'活动热点榜',
            //   val:0
            // },
          ]
        },
        // {
        //   id: 13,
        //   name: '流量',
        //   val: '',
        //   icon: require('../../assets/images/llicon.png'),
        //   children: [
        //     {
        //       id: 14,
        //       valea: '',
        //       path: '/buffer?type=3&status=1',
        //       name: '跨境热搜词',

        //     },
        //     {
        //       id: 15,
        //       valea: '',
        //       path: '/buffer?type=3&status=2',
        //       name: '热搜飙升词',
        //       val: 0
        //     },
        //     {
        //       id: 16,
        //       valea: '',
        //       path: '/buffer?type=3&status=3',
        //       name: '机会蓝海词',
        //       val: 0
        //     }
        //   ]
        // },
        {
          id: 19,
          name: '市场',
          val: '',
          icon: require('../../assets/images/scicon.png'),
          children: [
            {
              id: 20,
              valea: '',
              path: '/buffer?type=4&status=1',
              name: '品类热销榜',

            },
            {
              id: 21,
              valea: '',
              path: '/buffer?type=4&status=2',
              name: '品类飙升榜',
              val: 0
            },
            {
              id: 22,
              valea: '',
              path: '/buffer?type=4&status=3',
              name: '蓝海品类',
              val: 0
            }
          ]
        },
        // {
        //   id: 23,
        //   name: '竞品',
        //   icon: require('../../assets/images/jpicon.png'),
        //   children: [
        //     {
        //       id: 24,
        //       path: '/jp?type=1',
        //       name: '竞品分析',

        //     },
        //   ]
        // },
        // {
        //   id: 25,
        //   name: '竞店',
        //   icon: require('../../assets/images/jdicon.png'),
        //   children: [
        //     {
        //       id: 26,
        //       path: '/jp?type=2',
        //       name: '竞店分析',
        //     },
        //   ]
        // },
        {
          id: 27,
          name: '收藏',
          val: '',
          icon: require('../../assets/images/collect.png'),
          children: [
            {
              id: 28,
              valea: '',
              path: '/collect?type=1',
              name: '收藏的店铺',

            },
            {
              id: 29,
              valea: '',
              path: '/collect?type=2',
              name: '收藏的商品',
              val: 0
            },
          ]
        },
      ],
      name: '',
      menuIcon: {
        '1': 'iconfont iconuser',
        '3': 'iconfont iconshangpin',
        '5': 'iconfont iconicon--copy',
        '7': 'iconfont iconquanxian',
        '8': 'iconfont iconziyuan'
      },
      //定义是否折叠
      isCollapse: false,
      amune: false,
      //是否展现折叠动态效果
      isCollapseTransition: false,
      //定义默认高亮
      defaultActive: '',
      defaultActivename: '',
      user: {},
      //图搜同款弹窗
      showSearch: false,
      show_vip: false,
      path: '',
      showLogin: false,
      //更换表头
      query: { showSticky: false },
      userBenefits: {},
    }
  },
  created() {
    // this.defaultActive = sessionStorage.getItem("activeMenu")
    // this.defaultActivename = sessionStorage.getItem("defaultActivename")
    // // this.common.sourcea(sessionStorage.getItem('token'))
    // this.name = window.sessionStorage.getItem('name')
    // if(this.defaultActive == undefined) {
    //   this.ccbac('/home', '首页')
    // }
    // let sid = this.$cookie.get('SID');
    // if(sid) {
    //   localStorage.setItem('h5_token', sid);
    // }
    // console.log('cookie', sid);
    this.$nextTick(() => {
      this.autoLogin();
    })
  },
  components: {
    same_pic,
  },
  computed: {
    source() {
      console.log('source', this.$store.state.source)
      return this.$store.state.source;
    },
    listData() {
      return this.$store.state.id
    },
    listDatad() {
      return this.$store.state.idd
    },
    listDataa() {
      return this.$store.state.ida
    },
    listDatac() {
      return this.$store.state.idc
    },
    listDatab() {
      return this.$store.state.idb
    },
    is_vip() {
      return this.user && this.user.level && this.user.level > 10
    },
    is_svip() {
      return this.user && this.user.level && this.user.level > 20
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        console.log('$route', route)
        this.path = route.path;
        const query = route.query;
        let ticket = query.ticket ? query.ticket : '';
        if(ticket) {
          localStorage.setItem('h5_token', ticket);
          this.autoLogin();
        }
      },
      immediate: true,
    },
    listData(newVal, oldVal) {
      // 数据变化后，调用b组件内的函数
      let ca = this.menuList[1]
      ca.val = newVal
      this.menuval = newVal
      this.$forceUpdate()

    },
    '$route.path': {
      handler(toPath, fromPath) {
        this.defaultActivename = sessionStorage.getItem("defaultActivename")
        this.defaultActive = sessionStorage.getItem("activeMenu")
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },

    listDatad(newVal, oldVal) {
      // 数据变化后，调用b组件内的函数

      let ba = this.menuList[4]
      let a = sessionStorage.getItem('a')
      let b = Number(a) + newVal
      ba.val = b
      this.menuvald = newVal

      this.$forceUpdate()

    },
    listDataa(newVal, oldVal) {
      // 数据变化后，调用b组件内的函数
      let ca = this.menuList[4].children
      ca[1].val = newVal + this.$store.state.idd
      this.menuvala = newVal
      sessionStorage.setItem('a', newVal)
      if(Number(newVal) > 0) {
        sessionStorage.setItem('aca', 1)
      } else {
        sessionStorage.setItem('aca', 0)
      }
      this.$forceUpdate()

    },
    listDatac(newVal, oldVal) {
      // 数据变化后，调用b组件内的函数
      let ca = this.menuList[4].children
      ca[2].val = newVal
      this.menuvalb = newVal
      let a = sessionStorage.getItem('a')
      let b = Number(a) + newVal
      sessionStorage.setItem('a', b)
      if(Number(newVal) > 0) {
        sessionStorage.setItem('acc', 1)
      } else {
        sessionStorage.setItem('acc', 0)
      }
      this.$forceUpdate()

    },
    listDatab(newVal, oldVal) {
      // 数据变化后，调用b组件内的函数
      let ca = this.menuList[4].children
      ca[0].val = newVal
      this.menuvalc = newVal
      let a = sessionStorage.getItem('a')
      let c = Number(a) + newVal
      sessionStorage.setItem('a', c)
      if(Number(newVal) > 0) {
        sessionStorage.setItem('acb', 1)
      } else {
        sessionStorage.setItem('acb', 0)
      }
      this.$forceUpdate()

    },


  },
  mounted() {
    // window.addEventListener("setItem", () => {
    //   this.defaultActivename=sessionStorage.getItem("defaultActivename")
    //  this.defaultActive = sessionStorage.getItem("activeMenu")
    //     // this.role = sessionStorage.getItem("role");
    //     console.log(123,456);

    //   });
    //  this.defaultActivename=sessionStorage.getItem("defaultActivename")
    //  this.defaultActive = sessionStorage.getItem("activeMenu")
  },



  methods: {
    dealSticky(data) {
      console.log(data);
      this.query = Object.assign({}, data);
    },
    toWeb() {
      let jumpUrl = process.env.VUE_APP_HOME_URL;
      window.location.href = jumpUrl;
    },
    toCenter() {
      let jumpUrl = process.env.VUE_APP_CENTER_URL;
      window.location.href = jumpUrl;
    },
    tochangeSource(data) {
      if(!(this.user && this.user.id && this.user.id > 0)) {
        this.showLogin = true;
        return;
      }

      this.$store.commit('setSource', data);
      let now = this.$route.path;
      console.log('now', now);
      if(now != '/pd') {
        this.ccbac('/buffer?type=1&status=1', '商品热销榜')
      }
    },
    async autoLogin() {
      const { data: res } = await getUserInfo();
      setTimeout(() => {
        if(res.code == '0000') {
          const { data } = res;
          if(data) {
            const { user, userBenefits } = data;
            if(user) {
              console.log('confirmLoginByMobile', res, data);
              this.user = user;
              let is_vip = false;
              if(userBenefits && userBenefits.length > 0) {
                userBenefits.map(v => {
                  if(v && v.productCode && v.productCode == '1002') {
                    this.userBenefits = Object.assign({}, v);
                    is_vip = v.isValid == 0 ? false : true;
                  }
                })
              }
              console.log('index', is_vip);
              this.$cookie.set('is_vip', is_vip);
            } else {
              this.user = {};
            }
          } else {
            this.user = {};
          }
        } else {
          this.user = {};
        }
      }, 1000);
    },
    searchPics() {
      if(!(this.is_svip || this.is_vip)) {
        this.show_vip = true;
        return;
      }
      this.showSearch = true
    },
    handleCommand(data) {
      console.log('handleCommand', data);
      if(data == 'logout') {
        this.logout();
      }
    },
    async logout() {
      console.log('logout');
      let param = new URLSearchParams();
      const { data: res } = await loginout(param)
      //1.删除token数据
      localStorage.clear()
      // this.common.close()
      this.$cookie.set('is_vip', false);
      this.user = {};
      //2.跳转到用户登录页面  注意路径的写法 加/
    },
    toLogin() {
      let jumpUrl = process.env.VUE_APP_LOGIN_URL;
      let redirectUrl = process.env.VUE_APP_MANAGE_URL + this.$route.fullPath;
      let az = Base64.encode(redirectUrl);
      window.location.href = jumpUrl + '?redirectUrl=' + az;
    },
    // async getMenuList() {
    // //  const {data: result} =  await this.$http.get('/rights/getRightsList')
    // //  if(result.status !== 200) return this.$message.error("左侧菜单查询失败")
    //  //如果请求正常,则将返回值结果赋值给vue的data
    //  this.menuList = result.data

    // },
    //设定左侧折叠展开效果
    collspseClick() {
      this.isCollapse = !this.isCollapse
    },
    ccbac(activeMenu, e) {
      console.log(123, activeMenu, e);
      window.sessionStorage.setItem("activeMenu", activeMenu)
      window.sessionStorage.setItem("defaultActivename", e)
      this.defaultActivename = e
      this.defaultActive = activeMenu
      this.$router.push(activeMenu)
    },
    defaultActiveMenu(activeMenu, e) {
      //为了实现返回之后的选中效果,应该将数据保存到第三方中sessionStory
      window.sessionStorage.setItem("activeMenu", activeMenu)
      window.sessionStorage.setItem("defaultActivename", e)
      this.defaultActivename = e
      this.defaultActive = activeMenu
      // let a = {
      //   name:e,
      //   url:activeMenu
      // }
      // this.editableTabs.push(a)
      this.$forceUpdate()
    },

    vae(e) {
      // var c= sessionStorage.getItem('type')
      this.$forceUpdate()
      console.log(e);
      //         if(c == 1){
      //         this.$router.push({
      //         path:'/certi',
      //         query:{
      //          djs:1,
      //         }
      //       })
      //         }
      //         if(c == 2){
      //           this.$router.push( '/certi')
      //         }
    }
  }
}
</script>
<!-- 防止样式重叠 -->
<style lang="less" scoped>
/deep/ .dialogwidth {
  min-width: 300px;
}
.actionaac {
  width: 1293px !important;
}
.action {
  color: #4975e9 !important;
  font-weight: 600;
  /deep/.el-dropdown {
    color: #4975e9 !important;
    font-weight: 600;
  }
}
.actiona {
  color: #4975e9 !important;
  background-color: #ecf5ff !important;
  font-weight: 600;
  /deep/.el-dropdown {
    color: #4975e9 !important;
    font-weight: 600;
  }
}
.el-aside {
  // background-color: #4095e5;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #f7f7fc;
}
.iconfont {
  margin-right: 8px;
}

//定义折叠项
.leftCollapse {
  //设定背景色
  background-color: #3377b7;
  //定义字体大小
  font-size: 10px;
  //定义行高
  line-height: 30px;
  //定义颜色
  color: #ffffff;
  //设置居中
  text-align: center;
  //设定字符间距
  letter-spacing: 4px;
  //鼠标放上之后设置为小手
  cursor: pointer;
}
.red {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  background-color: red !important;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  color: #fff !important;
  margin-left: 20px !important;
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 5;
}
.el-menu-item .is-active {
  position: relative;
}
.home-container {
  height: 100%;
  width: 100%;
  /deep/ .el-header {
    padding: 0;
  }
  &-header {
    /deep/.el-menu-item.is-active {
      color: #4975e9;
    }
    /deep/.is-active {
      background-color: #4974e91c;
    }
    /deep/.el-submenu__title {
      text-align-last: left !important;
      background-color: #fff !important;
    }
    /deep/ .el-table__empty-block {
      display: none;
    }
    /deep/ .el-table .el-table__cell {
      padding: 3px 0;
    }
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    z-index: 999;
    &-table {
      // margin-right: 155px;
      padding: 0 37px;
    }
    &-left {
      background: #fff;
      text-align: center;
      align-items: center;
      margin-left: 20px;
      color: #000;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    &-right {
      display: flex;
      align-items: center;
      &-user {
        &-drop {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      &-btn {
        background: #333333;
        color: #fff;
        line-height: 36px;
        text-align: center;
        border-radius: 23px;
        cursor: pointer;
        width: 99px;
      }
      font-size: 18px;
      margin-right: 30px;
      display: flex;
      align-items: center;
    }
    &-source {
      display: flex;
      align-content: center;
      height: 60px;
      margin-left: 20px;
      &-item {
        cursor: pointer;
        padding: 8px 15px;
        color: #999999;
        margin-right: 15px;
        &-choose {
          color: #4975e9;
        }
      }
    }
  }
  &-side {
    /deep/.el-menu-item.is-active {
      color: #4975e9;
    }
    /deep/.is-active {
      background-color: #4974e91c;
    }
    /deep/.el-submenu__title {
      text-align-last: left !important;
      background-color: #fff !important;
    }
  }
  &-main {
    position: relative;
    &-qr {
      position: fixed;
      background: #fff;
      bottom: 20px;
      left: 10px;
      padding: 10px;
      z-index: 1;   
      &-desc {
        text-align: center;
      }
    }
  }
}

// /deep/.el-submenu__title i{
//   color:  #fff !important;
// }
// /deep/.el-menu-item i{
//   color:  #fff !important;
// }
</style>

